import React from 'react';
import { Link } from 'react-router-dom';
import NotFounf from "../img/concept/404.svg";


const NotFound = () => (
  <div>
    
    

    <section class="wrapper ">
        <div class="container pb-8 text-center">
          <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            

            <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
            <h1>404 - Not Found!</h1>
              <p class="lead fs-lg mb-7">
               Ahh.. You think you are on wrong route! 
               Yeah!
              </p>
              <p class="lead fs-lg mb-7">
                Let's Take a <b>RIGHT TURN</b>{" "}
              </p>
              <Link to="/">
              <span>
                <a
                  class="btn btn-primary rounded-pill me-2"
                >
                  Drive Me Home!
                </a>
              </span>
    </Link>
              
            </div>
            <div class="col-lg-7">
              <figure>
                <img class="w-auto" src={NotFounf} srcset={NotFounf} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
  </div>
);

export default NotFound;