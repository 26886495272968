import react from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home';
import NotFound from './components/notFound';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-206039422-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route component={NotFound} />
    </Switch>
  </Router>
    )
}

export default App;
