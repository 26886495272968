import "./home.css";
import family from "../img/concept/car-family.svg";
import carSearch from "../img/concept/car-search.svg";
import saveMoney from "../img/concept/save-money.svg";
import carResearch from "../img/concept/car-research.svg";
import bestPrice from "../img/concept/best-on-road-price.png";
import noCostEmi from "../img/concept/no-cost-emi.png";
import deliveryExperience from "../img/concept/car-delivery-experience.png";
import logo from "../img/carzo-logo.svg";

import ReactGA from "react-ga";
ReactGA.initialize("UA-206039422-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div class="content-wrapper">
      <header class="wrapper bg-soft-primary">
        <nav class="navbar center-nav transparent navbar-expand-lg navbar-light">
          <div class="container flex-lg-row flex-nowrap align-items-center">
            <div class="navbar-brand w-100">
              <a href="#">
                <img class="carzo-logo" src={logo} srcset={logo} alt="carzo logo" />
              </a>
            </div>
            <div class="navbar-collapse offcanvas-nav">
              <div class="offcanvas-header d-lg-none d-xl-none">
                <a href="">
                  <img src={logo} srcset={logo} alt="logo carzo" />
                </a>
                <button
                  type="button"
                  class="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                  aria-label="Close"
                ></button>
              </div>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="#why">
                    Why carZo?
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#how">
                    How it works?
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#save-more">
                    Save More
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#research">
                    Research
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#footer">
                    Contact US
                  </a>
                </li>
              </ul>
            </div>

            <div class="navbar-other w-100 d-flex ms-auto">
              <ul
                class="navbar-nav flex-row align-items-center ms-auto"
                data-sm-skip="true"
              >
                {/* <li class="nav-item dropdown language-select text-uppercase">
                <a class="nav-link dropdown-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">En</a>
                <ul class="dropdown-menu">
                  <li class="nav-item"><a class="dropdown-item" href="#">En</a></li>
                  <li class="nav-item"><a class="dropdown-item" href="#">De</a></li>
                  <li class="nav-item"><a class="dropdown-item" href="#">Es</a></li>
                </ul>
              </li> */}
                <li class="nav-item d-lg-none">
                  <div class="navbar-hamburger">
                    <button
                      class="hamburger animate plain"
                      data-toggle="offcanvas-nav"
                    >
                      <span></span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section class="wrapper bg-gradient-primary">
        <div class="container pb-8 text-center">
          <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div class="col-lg-7">
              <figure>
                <img class="w-auto" src={family} srcset={family} alt="luxurious buying" />
              </figure>
            </div>

            <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
              <h1 class="display-1 mb-5 mx-md-n5 mx-lg-0">
                Planning to Buy a New Car?
              </h1>
              <p class="lead fs-lg mb-7">
                We make your car buying experience <b>Luxurious</b> and{" "}
                <b>Rewarding</b> at the same time{" "}
              </p>
              <p class="lead fs-lg mb-7">
                That’s 2+2=<b>5</b>{" "}
              </p>
              <span>
                <a
                  class="btn btn-primary rounded-pill me-2"
                  href="https://api.whatsapp.com/send/?phone=919529311880&text=hello&app_absent=0"
                >
                  Talk to Us Now
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section class="wrapper bg-light" id="why">
        <div class="container pt-4 pb-8 ">
          <div class="row text-center">
            <div
              id="services"
              class="col-md-10 offset-md-1 col-lg-8 offset-lg-2"
            >
              <h2 class="fs-16 text-uppercase text-muted mb-3">Why Carzo?</h2>
              <h3 class="display-4 mb-10 px-xl-10">
                The service we offer is specifically designed to meet your
                needs.
              </h3>
            </div>
          </div>

          <div class="position-relative">
            <div class="row gx-md-5 gy-5 text-center ">
              <div class="col-md-6 col-xl-4">
                <div class="card shadow-lg">
                  <div class="card-body">
                    <img
                      src={bestPrice}
                      class="img-fluid img-thumbnail mb-6"
                      alt="specifically designed service"
                    />
                    <h4>Guaranteed Best On Road Price!</h4>
                    {/* <p class="mb-2">We offer never before on-road price for your dream car! We</p> */}
                    {/* <a href="#" class="more hover link-red">Learn More</a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-4">
                <div class="card shadow-lg">
                  <div class="card-body">
                    <img
                      src={deliveryExperience}
                      class="img-fluid img-thumbnail mb-6"
                      alt="best on road price"
                    />
                    <h4>Exceptional Buying Experience</h4>
                    {/* <p class="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.Nulla vitae elit libero, a pharetra augue. D</p> */}
                    {/* <a href="#" class="more hover link-green">Learn More</a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-4">
                <div class="card shadow-lg">
                  <div class="card-body">
                    <img
                      src={noCostEmi}
                      class="img-fluid img-thumbnail mb-6"
                      alt="great buying experience"
                    />
                    <h4>No Cost EMI for Down Payments!</h4>
                    {/* <p class="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.Nulla vitae elit libero, a pharetra augue. D</p> */}
                    {/* <a href="#" class="more hover link-blue">Learn More</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="wrapper bg-light">
        <div class="container pb-8 pt-4">
          <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div class="col-lg-7 order-lg-2">
              <figure>
                <img class="w-auto" src={carSearch} srcset={carSearch} alt="how it works" />
              </figure>
            </div>

            <div class="col-lg-5" id="how">
              <h2 class="fs-16 text-uppercase text-muted mb-3">
                How it Works?
              </h2>
              <h3 class="display-4 mb-5">
                We don't sell cars, we deliver experience!
              </h3>
              <p class="mb-6">
                Our end-to-end system facilitates to get a never before
                experience in buying your brand new car.
              </p>
              <div class="row gy-3">
                <div class="col-xl-6">
                  <ul class="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i class="uil uil-check"></i>
                      </span>
                      <span>Select Brand</span>
                    </li>
                    <li class="mt-3">
                      <span>
                        <i class="uil uil-check"></i>
                      </span>
                      <span>Get Quote</span>
                    </li>
                  </ul>
                </div>

                <div class="col-xl-6">
                  <ul class="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i class="uil uil-check"></i>
                      </span>
                      <span>Select Model</span>
                    </li>
                    <li class="mt-3">
                      <span>
                        <i class="uil uil-check"></i>
                      </span>
                      <span>Talk to carZo </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=" wrapper bg-light" id="save-more">
        <div class="container pb-16 text-center">
          <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div class="col-lg-7">
              <figure>
                <img class="w-auto" src={saveMoney} srcset={saveMoney} alt="save more money" />
              </figure>
            </div>

            <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
              <h1 class="display-1 mb-5 mx-md-n5 mx-lg-0">Save More Money! </h1>
              <p class="lead fs-lg mb-7">
                We help you to get your dream car at an{" "}
                <b>Exceptional Price!</b>
              </p>
              <span>
                <a
                  class="btn btn-primary rounded-pill me-2"
                  href="https://api.whatsapp.com/send/?phone=919529311880&text=hello&app_absent=0"
                >
                  Money! Money! Money!
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section
        class=" wrapper bg-light angled upper-end lower-end"
        id="research"
      >
        <div class="container pb-16 text-center ">
          <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
              <h1 class="display-1 mb-5 mx-md-n5 mx-lg-0">Research </h1>
              <p class="lead fs-lg mb-7">
                Our platform backed by{" "}
                <b>Artificial Intelligence & Machine Learning</b> helps you find
                the right car for you!
              </p>
              <span>
                <a
                  class="btn btn-primary rounded-pill me-2"
                  href="https://api.whatsapp.com/send/?phone=919529311880&text=hello&app_absent=0"
                >
                  Find a Car
                </a>
              </span>
            </div>
            <div class="col-lg-7">
              <figure>
                <img
                  class="w-auto"
                  src={carResearch}
                  srcset={carResearch}
                  alt="AI and ML"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <footer class="bg-navy text-inverse " id="footer">
        <div class="container pt-15 pt-md-17 pb-13 pb-md-15">
          <div class="d-lg-flex flex-row align-items-lg-center">
            <h3 class="display-4 mb-6 mb-lg-0 pe-lg-20 pe-xl-22 pe-xxl-25 text-white">
              Book your dream car at carZo.in!
            </h3>
            <a
              href="#"
              class="btn btn-primary rounded-pill mb-0 text-nowrap"
              href="https://api.whatsapp.com/send/?phone=919529311880&text=hello&app_absent=0"
            >
              Talk to Us Now
            </a>
          </div>

          <hr class="mt-11 mb-12" />
          <div class="row gy-6 gy-lg-0">
            <div class="col-md-4 col-lg-3">
              <div class="widget">
                <img
                  class="mb-4"
                  src={logo}
                  srcset={logo}
                  alt="logo"
                />
                <p class="mb-4">
                  © 2021 carZo.in <br class="d-none d-lg-block" />
                  All rights reserved.
                </p>
                <nav class="nav social social-white">
                  <a target="_blank" href="https://twitter.com/carzo_in">
                    <i class="uil uil-twitter"></i>
                  </a>
                  <a target="_blank" href="https://www.facebook.com/Carzoin-100435799033546/">
                    <i class="uil uil-facebook-f"></i>
                  </a>
                  {/* <a target="_blank" href="#">
                    <i class="uil uil-dribbble"></i>
                  </a>
                  <a target="_blank" href="#">
                    <i class="uil uil-instagram"></i>
                  </a> */}
                  <a target="_blank" href="https://www.youtube.com/channel/UCwXVx-LnqfYG-BByZ7T_bBA">
                    <i class="uil uil-youtube"></i>
                  </a>
                </nav>
              </div>
            </div>

            <div class="col-md-4 col-lg-3">
              <div class="widget">
                <h4 class="widget-title text-white mb-3">Get in Touch</h4>
                <address class="pe-xl-15 pe-xxl-17">
                  Sr. 110/3 110/4/1, Wakad, Pune, 411057
                </address>
                <a href="mailto:info@carzo.in">info@carzo.in</a>
                <br /> +91 20 40042200
              </div>
            </div>

            <div class="col-md-4 col-lg-3">
              <div class="widget">
                <h4 class="widget-title text-white mb-3">Learn More</h4>
                <ul class="list-unstyled mb-0">
                  <li>
                    <a href="#why">Why carZo?</a>
                  </li>
                  <li>
                    <a href="#how">How it works?</a>
                  </li>
                  <li>
                    <a href="#save-more">Save More</a>
                  </li>
                  <li>
                    <a href="#research">Research</a>
                  </li>
                  <li>
                    <a href="#footer">Contact US</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12 col-lg-3">
              <div class="widget">
                <h4 class="widget-title text-white mb-3">Our Newsletter</h4>
                <p class="mb-5">
                  Subscribe to our newsletter to get our news & deals delivered
                  to you.
                </p>
                <div class="newsletter-wrapper">
                  <div id="mc_embed_signup2">
                    <form
                      action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&amp;id=b49ef47a9a"
                      method="post"
                      id="mc-embedded-subscribe-form2"
                      name="mc-embedded-subscribe-form"
                      class="validate dark-fields"
                      target="_blank"
                      novalidate
                    >
                      <div id="mc_embed_signup_scroll2">
                        <div class="mc-field-group input-group form-label-group">
                          <input
                            type="email"
                            value=""
                            name="EMAIL"
                            class="required email form-control"
                            placeholder="Email Address"
                            id="mce-EMAIL2"
                          />
                          <label for="mce-EMAIL2">Email Address</label>
                          <input
                            type="submit"
                            value="Join"
                            name="subscribe"
                            id="mc-embedded-subscribe2"
                            class="btn btn-primary"
                          />
                        </div>
                        <div id="mce-responses2" class="clear">
                          <div class="response" id="mce-error-response2"></div>
                          <div
                            class="response"
                            id="mce-success-response2"
                          ></div>
                        </div>
                        {/* <div aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabindex="-1" value=""/></div> */}
                        <div class="clear"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
